<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Ladies Price</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="ladiesPriceInsert">
              <div class="card-body">
                <div class="form-row">
                  <div class="col-md-6 form-group">
                    <label>Ladies Type</label>
                    <model-list-select
                      :list="ladies_types"
                      v-model="form.type_id"
                      option-value="id"
                      option-text="name"
                      placeholder="Select Type"
                    >
                    </model-list-select>
                    <small class="text-danger" v-if="errors.type_id">{{
                      errors.type_id[0]
                    }}</small>
                  </div>
                  <div class="col-md-6 form-group">
                    <label>Ladies Category</label>
                    <model-list-select
                      :list="ladies_categories"
                      v-model="form.category_id"
                      option-value="id"
                      option-text="name"
                      placeholder="Select Category"
                    >
                    </model-list-select>
                    <small class="text-danger" v-if="errors.category_id">{{
                      errors.category_id[0]
                    }}</small>
                  </div>
                  <div class="col-md-6 form-group ">
                    <label>Company Fee</label>
                    <money
                      v-model="form.company_fee"
                      class="form-control"
                    ></money>
                    <small class="text-danger" v-if="errors.company_fee">{{
                      errors.company_fee[0]
                    }}</small>
                  </div>
                  <div class="col-md-6 form-group ">
                    <label>Fee Ladies</label>
                    <money
                      v-model="form.fee_ladies"
                      class="form-control"
                    ></money>
                    <small class="text-danger" v-if="errors.fee_ladies">{{
                      errors.fee_ladies[0]
                    }}</small>
                  </div>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { apiGet, apiPost } from "../../services/api";

export default {
  name: "FormLadiesPrice",
  data() {
    return {
      name: "Form Ladies Price",
      form: {
        type_id: "",
        category_id: "",
        company_fee: 0,
        fee_ladies: 0,
      },
      ladies_types: [],
      ladies_categories: [],
      errors: {},
      isSubmitting: false,
      edit: false,
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.getData();
    }
    this.getType();
  },
  methods: {
    getData() {
      let id = this.$route.params.id;
      apiGet("ladies_price/" + id).then((result) => {
        this.form = result.data.data;
        this.edit = true;
      });
    },
    getType() {
      apiGet("ladies_type").then((result) => {
        this.ladies_types = result.data.data;
        apiGet("ladies_category").then((result) => {
          this.ladies_categories = result.data.data;
        });
      });
    },
    ladiesPriceInsert() {
      this.isSubmitting = true;
      if (this.edit == false) {
        apiPost("ladies_price/store", this.form)
          .then(() => {
            this.$noty.success("Your Ladies Price has been saved!");
            this.$router.push({ name: "LadiesPrice" });
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
          });
      } else {
        let id = this.$route.params.id;
        apiPost("ladies_price/update/" + id, this.form)
          .then(() => {
            this.$noty.success("Your Ladies Price has been saved!");
            this.$router.push({ name: "LadiesPrice" });
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
